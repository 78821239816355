export default function (id, inView) {
    if (inView) {
        var element = document.getElementById(id),
            target = element.dataset.target,
            i = element.innerHTML;
        if (element.innerText == target) return
        var intervalId = setInterval(function () {
            if (i == target) {
                clearInterval(intervalId);
            }
            element.innerHTML = i;
            i++;
        }, 20);
    }
};