import React, { useLayoutEffect } from "react"
import sliderImage from "../../images/glowna/01.jpg"
import sliderImageMobile from "../../images/m_01.jpg"
import sliderImageSmall from "../../images/m_01b.jpg"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import ScrollBlock from "../../components/scrollBlock"

const HomeBanner = () => {
    const { t } = useTranslation();
    useLayoutEffect(() => {
      setTimeout(function () {
        initTextAnimSlider()
      }, 100)

      function initTextAnimSlider() {
        var textAnimHolder = document.querySelector("[data-words]")
        var textAnimItem = document.querySelectorAll(".text-anim-item")
        var textAnimItems = document.querySelector(".text-anim-items")
        var animIn = "anim-in"
        var animOut = "anim-out"
        var animNextItem = null
        var animPrevItem = null
        var animFirstLoad = false
        var animDuration = textAnimHolder.getAttribute("data-delay")
        var animCounter = 0
        var setTimeAnim
        var setTimeAnimResize

        animFunc()
        getHolderWidth()

        function animFunc() {
          clearTimeout(setTimeAnim)

          setTimeAnim = setTimeout(
            function () {
              animFirstLoad = true

              if (animPrevItem !== null) {
                animPrevItem.classList.add(animOut)
              }
              animNextItem = textAnimItems.children[animCounter]
              animNextItem.classList.remove(animOut)
              animNextItem.classList.add(animIn)

              animPrevItem = animNextItem

              if (animCounter === textAnimItem.length - 1) {
                animCounter = 0
              } else {
                animCounter++
              }
              animFunc()
            },
            animFirstLoad ? animDuration : 100
          )
        }

        function getHolderWidth() {
          var itemsWidth = []

          for (var i = 0; i < textAnimItem.length; i++) {
            itemsWidth.push(textAnimItem[i].getBoundingClientRect().width)
            console.log(textAnimItem[i].getBoundingClientRect().width)
          }

          // var biggestWidth = Math.max.apply(null, itemsWidth) + 'px';

          textAnimHolder.style.width = itemsWidth[animCounter] + "px"
        }

        function resizeHandler() {
          clearTimeout(setTimeAnim)
          clearTimeout(setTimeAnimResize)
          getHolderWidth()

          setTimeAnimResize = setTimeout(function () {
            animFunc()
          }, 50)
        }

        if (typeof window !== `undefined`) {
          window.addEventListener("resize", resizeHandler)
          window.addEventListener("orientationchange", resizeHandler)
        }
      }
    })
    return (
      <div id="banner" className="slider slider--home relative bg-black">
        <picture className="scania-logo">
          <source media="(max-width: 640px)" srcSet={sliderImageSmall}></source>
          <source
            media="(min-width: 641px) and (max-width: 1023px)"
            srcSet={sliderImageMobile}
          ></source>
          <source media="(min-width: 1024px)" srcSet={sliderImage}></source>
          <img src={sliderImage} alt="JPietrzak slider" />
        </picture>
        <div className="slider__description text-white">
          <div className="flex content-center flex-wrap w-full h-full">
            <div className="flex px-5 tb:px-0 items-center justify-center w-full flex-col">
              <h1 className="slider__title uppercase justify-center flex flex-wrap tb:mb-5">
                <span className="font-light text-center">
                  {t("home.bannerTitle01")}
                </span>
                <span
                  className="text-anim-items text-brand relative font-bold"
                  data-delay="4000"
                  data-words
                >
                  <span className="text-anim-item">
                    <span>{t("home.bannerTitle01a")}</span>
                  </span>
                  <span className="text-anim-item">
                    <span>{t("home.bannerTitle01b")}</span>
                  </span>
                  <span className="text-anim-item">
                    <span>{t("home.bannerTitle01c")}</span>
                  </span>
                </span>
              </h1>
              <h2 className="slider__subtitle text-xl uppercase font-bold">
                {t("home.subtitle")}
              </h2>
              <div className="slider__buttons mt-5 tb:mt-16">
                <Link to={t("general.fleetHref")} className="btn btn-primary">
                  {t("general.ourOffer")}
                </Link>
                <Link to={t("general.contactHref")} className="btn btn-secondary">
                  {t("general.colaborate")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ScrollBlock></ScrollBlock>
      </div>
    )
}

export default HomeBanner;