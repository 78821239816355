import React from 'react'
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import icon01 from "../../images/glowna/icon_02.svg"
import icon02 from "../../images/glowna/icon_03.svg"
import icon03 from "../../images/glowna/icon_04.svg"
import counter from "../../static/js/counter"
import { InView } from "react-intersection-observer"
import showElement from '../../static/js/showElement'
import video from '../../static/jpietrzak_loop_01.mp4'

const HomeSubBanner = () => {
  const { t } = useTranslation()
  return (
    <section id="el-01" className="bg-white to-show to-up to-fadein">
      <div className="max-w-screen-xl mx-auto">
        <div className="flex content-center flex-wrap bg-white box-over box-over--home relative">
          <div className="std:w-359 w-full z-10 flex content-center flex-wrap mh-790">
            <div className="px-5 w-full pb-6 std:px-10 std:pb-24 pt-12 xxl:pl-0">
              <h6 className="flex h6">{t("home.whoweare")}</h6>
              <h1 className="h1 font-bold text-dark mb-9 uppercase break-words">
                {t("home.company")}
              </h1>
              <p className="mb-6 std:mb-12">{t("home.companyDesc")}</p>
              <Link to={t("general.fleetHref")} className="btn btn-primary">
                <InView
                  as="span"
                  triggerOnce="false"
                  threshold="0.1"
                  onChange={(inView, entry) => {
                    showElement(document.getElementById("el-01"), inView)
                  }}
                >
                  {t("general.seemore")}
                </InView>
              </Link>
            </div>
          </div>
          <div className="std:w-39 w-full flex content-center flex-wrap relative about-video">
            <video
              autoPlay
              muted
              loop
              playsInline
              poster=""
              className="video"
              src={video}
            ></video>
            <p className="text-white absolute uppercase">{t("home.checkUs")}</p>
          </div>

          <div className="std:w-251 w-full flex content-center flex-wrap about__info">
            <div className="info-graphs px-7 w-full divide-y divide-gray-200 flex std:flex-col flex-wrap justify-between">
              <div
                id="el-05"
                className="info-graph to-show to-fadein from-right w-full md:w-1/3 std:w-auto delay-400"
              >
                <InView
                  as=""
                  triggerOnce="true"
                  threshold="1"
                  onChange={(inView, entry) => {
                    counter("counter-29", inView)
                    showElement(document.getElementById("el-05"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon01} className="mr-10" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark js-counter"
                      id="counter-29"
                      data-target="32"
                    >
                      0
                    </h1>
                  </div>

                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.exp"></Trans>
                  </h5>
                </InView>
              </div>
              <div
                id="el-06"
                className="info-graph to-show to-fadein from-right w-full md:w-1/3 std:w-auto delay-700"
              >
                <InView
                  as="div"
                  threshold="0.5"
                  triggerOnce="true"
                  onChange={(inView, entry) => {
                    counter("counter-270", inView)
                    showElement(document.getElementById("el-06"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon02} className="mr-10" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark js-counter"
                      id="counter-270"
                      data-target="290"
                    >
                      200
                    </h1>
                  </div>
                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.workers"></Trans>
                  </h5>
                </InView>
              </div>
              <div
                id="el-07"
                className="info-graph to-show to-fadein from-right  w-full md:w-1/3 std:w-auto delay-1000"
              >
                <InView
                  as="div"
                  threshold="0.5"
                  triggerOnce="true"
                  onChange={(inView, entry) => {
                    counter("counter-110", inView)
                    showElement(document.getElementById("el-07"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon03} className="mr-10" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark"
                      id="counter-110"
                      data-target="175"
                    >
                      20
                    </h1>
                  </div>
                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.sets2"></Trans>
                  </h5>
                </InView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeSubBanner;