import React from 'react'
import { Link } from "gatsby"
import { useTranslation, Trans } from "react-i18next"
import icon03 from "../../images/glowna/icon_04.svg"
import icon04 from "../../images/glowna/icon_05.svg"
import icon05 from "../../images/glowna/icon_06.svg"
import galleryImg01 from "../../images/Image-12.jpg"
import galleryImg02 from "../../images/Image-11.jpg"
import { InView } from "react-intersection-observer"
import counter from "../../static/js/counter"
import showElement from "../../static/js/showElement"
import parallaxBgd from '../../static/js/parallax'

const HomeGallery = () => {
  const { t } = useTranslation();
  return (
    <section>
      <InView
        as=""
        triggerOnce="false"
        threshold="1"
        onChange={(inView, entry) => {
          showElement(document.getElementById("el-02"), inView);
          parallaxBgd(document.getElementById("el-gallery"), 0.15);
        }}
      >
        <h1
          id="el-02"
          className="h1 to-show to-up to-fadein section-title uppercase text-center font-light text-dark"
        >
          {t("general.checkOurFleet01")}{" "}
          <span className="font-bold">{t("general.checkOurFleet02")}</span>
        </h1>
      </InView>
      <div id="el-gallery" className="gallery flex flex-wrap">
        <div className="w-full px-5 std:px-0 std:w-361 order-2 tb:order-1 gallery-bgd relative">
          <div
            id="el-03"
            className="to-show to-fadein from-left gallery-bgd__inner relative h-full w-full z-10"
          >
            <div className="absolute gallery-link flex flex-column flex-wrap justify-center content-center">
              <svg width="100%" className="svg-outline h1--large">
                <text
                  x="50%"
                  y="50%"
                  alignmentBaseline="middle"
                  textAnchor="middle"
                  className="svg-outline__text font-bold"
                >
                  +24
                </text>
              </svg>
              <InView
                as=""
                triggerOnce="false"
                threshold="1"
                onChange={(inView, entry) => {
                  showElement(document.getElementById("el-03"), inView)
                }}
              ></InView>
              <Link
                to={`${t("general.fleetHref")}/#gallery`}
                className="btn btn-bare text-white"
              >
                {t("general.photoGallery")}
              </Link>
            </div>
          </div>
        </div>
        <div className="std:w-638 order-1 tb:order-2">
          <div className="w-full max-w-screen-tb">
            <div className="pb-10 px-5 std:pl-20 std:pb-20">
              <h6 className="flex h6 text-white">{t("general.ourFleet")}</h6>
              <h1 className="h1 font-bold uppercase text-white mb-9">
                {t("general.110sets")}
              </h1>
              <Link to={t("general.fleetHref")} className="btn btn-primary">
                {t("general.seemore")}
              </Link>
            </div>
            <div className="hidden md:flex flex-row flex-wrap">
              <InView
                as=""
                triggerOnce="false"
                threshold=".5"
                onChange={(inView, entry) => {
                  showElement(document.getElementById("gal-01"), inView)
                }}
              >
                <img
                  src={galleryImg01}
                  alt=""
                  id="gal-01"
                  className="to-show to-up to-fadein"
                />
              </InView>

              <InView
                as=""
                triggerOnce="false"
                threshold="1"
                onChange={(inView, entry) => {
                  showElement(document.getElementById("gal-02"), inView)
                }}
              >
                <img
                  src={galleryImg02}
                  alt=""
                  id="gal-02"
                  className="to-show to-up to-fadein"
                />
              </InView>
            </div>
          </div>
          <div className="bg-white">
            <div className="w-full flex-wrap flex flex-row max-w-screen-tb">
              <div
                id="el-04"
                className="info-graph info-graph--row w-full std:w-1/3 to-show to-up to-fadein"
              >
                <InView
                  as="div"
                  threshold="0.5"
                  triggerOnce="true"
                  onChange={(inView, entry) => {
                    counter("counter-135", inView)
                    showElement(document.getElementById("el-04"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon03} className="mr-4" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark js-counter"
                      id="counter-135"
                      data-target="175"
                    >
                      100
                    </h1>
                  </div>
                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.sets2"></Trans>
                  </h5>
                </InView>
              </div>
              <div
                id="el-08"
                className="info-graph info-graph--row w-full std:w-1/3 to-show to-up to-fadein"
              >
                <InView
                  as=""
                  threshold="0.5"
                  triggerOnce="true"
                  onChange={(inView, entry) => {
                    counter("counter-20k", inView)
                    showElement(document.getElementById("el-08"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon04} className="mr-6" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark js-counter"
                      id="counter-20k"
                      data-target="33000"
                    >
                      32900
                    </h1>
                  </div>
                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.monthly"></Trans>
                  </h5>
                </InView>
              </div>
              <div
                id="el-09"
                className="info-graph info-graph--row w-full std:w-1/3 to-show to-up to-fadein"
              >
                <InView
                  as=""
                  threshold="0.5"
                  triggerOnce="true"
                  onChange={(inView, entry) => {
                    counter("counter-avg", inView)
                    showElement(document.getElementById("el-09"), inView)
                  }}
                >
                  <div className="flex mb-4">
                    <img src={icon05} className="mr-6" width="66" alt="" />
                    <h1
                      className="h1--large font-bold text-dark js-counter"
                      id="counter-avg"
                      data-target="2"
                    >
                      0
                    </h1>
                  </div>
                  <h5 className="text-lg uppercase">
                    <Trans i18nKey="general.averageAge"></Trans>
                  </h5>
                </InView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeGallery;