import React from "react"
import routesImg from "../images/world.jpg"
import routesImgMobile from "../images/kierunki_mob.jpg"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import { InView } from "react-intersection-observer"
import showElement from "../static/js/showElement"
import img from '../images/dots_05.gif'
import mark from '../images/mark.png'

const RoutesSection = (props) => {
    const isHeader = props.includeHeader;
    const { t } = useTranslation();
    return (
      <section className="routes relative">
        {isHeader && (
          <h1
            id="el-routeTitle"
            className="h1 section-title uppercase text-center font-light text-dark break-words"
          >
            <InView
              as=""
              threshold="0.1"
              triggerOnce="true"
              onChange={(inView, entry) => {
                showElement(document.getElementById("el-routeTitle"), inView)
              }}
            >
              {t("route.title-01")}{" "}
              <span className="font-bold">{t("route.title-02")}</span>
            </InView>
          </h1>
        )}
        <InView
          as=""
          threshold="0.1"
          triggerOnce="true"
          onChange={(inView, entry) => {
            showElement(document.getElementById("el-route"), inView)
            document.getElementById("destinationsImg").src = img
          }}
        >
          <div className="relative">
            <picture>
              <source
                media="(max-width: 1023px)"
                srcSet={routesImgMobile}
              ></source>
              <source media="(min-width: 1024px)" srcSet={routesImg}></source>
              <img
                id="el-route"
                className="to-show to-fadein to-up"
                src={routesImg}
                alt="JPietrzak slider"
              />
            </picture>
            <div className="hidden md:block destinations absolute">
              <img className="dots" id="destinationsImg" />
              <img className="mark absolute" src={mark} />
            </div>
          </div>
          <div className="routes__description std:absolute std:text-white">
            <div className="p-4">
              <h6 className="flex h6">{t("route.subtitle")}</h6>
              <h1 className="h1 font-bold uppercase mb-9">
                {t("route.range")}
              </h1>
              <Link to={t("general.contactHref")} className="btn btn-primary">
                {t("route.btn")}
              </Link>
            </div>
          </div>
        </InView>
      </section>
    )
}

export default RoutesSection;